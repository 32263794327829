export class AddAdditionalInsuredRequest {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  type: 'owner' | 'lender' | 'property-manager';
}

export class RemoveAdditionalInsuredRequest {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
}

export class ModifyCoverageAmountRequest {
  coverageKey:
    | 'coverageA'
    | 'coverageB'
    | 'coverageC'
    | 'coverageD'
    | 'coverageE'
    | 'coverageF';
  targetAmount: number;
}

export class ModifyEffectiveDateRequest {
  effectiveDate: string;
}

export class AddCoverageTypeRequest {
  coverageType:
    | 'water-backup'
    | 'equipment-breakdown'
    | 'service-line'
    | 'scheduled-property'
    | 'windstorm-hail'
    | 'earthquake'
    | 'flood'
    | 'animal-liability';
}

export class RemoveCoverageTypeRequest {
  coverageType:
    | 'water-backup'
    | 'equipment-breakdown'
    | 'service-line'
    | 'scheduled-property'
    | 'windstorm-hail'
    | 'earthquake'
    | 'flood'
    | 'animal-liability';
}

export class ModifyOccupancyStatusRequest {
  occupancyStatus:
    | 'tenant-occupied'
    | 'owner-occupied'
    | 'vacant'
    | 'renovation';
}

export class ModifyDeductibleRequest {
  deductible: number;
}

export class ModifyPropertyRequest {
  type: 'roof' | 'pool' | 'dog' | 'other';
  details?: string;
}

export class OtherEndorsementRequest {
  description: string;
}

export type EndorsementRequestBodyType =
  | AddAdditionalInsuredRequest
  | RemoveAdditionalInsuredRequest
  | ModifyCoverageAmountRequest
  | ModifyEffectiveDateRequest
  | AddCoverageTypeRequest
  | RemoveCoverageTypeRequest
  | ModifyOccupancyStatusRequest
  | ModifyDeductibleRequest
  | ModifyPropertyRequest
  | OtherEndorsementRequest;

export enum EndorsementType {
  AddAdditionalInsured = 'add-additional-insured',
  AddCoverageType = 'add-coverage-type',
  // ModifyCoverageAmount = 'modify-coverage-amount',
  ModifyDeductible = 'modify-deductible',
  // ModifyEffectiveDate = 'modify-effective-date',
  ModifyOccupancyStatus = 'modify-occupancy-status',
  // ModifyProperty = 'modify-property',
  Other = 'other',
  // RemoveCoverageType = 'remove-coverage-type',
  // RemoveAdditionalInsured = 'remove-additional-insured',
}

export interface CreateEndorsementDTO {
  userId: string;
  policyId: string;
  type: EndorsementType;
  requestBody: EndorsementRequestBodyType;
}

export interface EndorsementDTO {
  id: string;
  userId: string;
  policyId: string;
  type: EndorsementType;
  requestBody: EndorsementRequestBodyType;
  status: 'pending' | 'complete';
  oldAnnualPremium: number;
  newAnnualPremium?: number;
}