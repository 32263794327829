import { Box, RadioGroup } from '@mui/material';
import * as React from 'react';
import {
  RadioOption,
  RadioOptionProps,
} from 'views/onboarding/components/RadioOption';
import { SectionWrapper } from 'views/onboarding/components/SectionWrapper';

export interface Props {
  value?: number;
  onSubmit: (val: number) => void;
  updating?: boolean;
}

const Options: Array<Omit<RadioOptionProps<number>, 'onSelect'>> = [
  {
    label: '$5,000',
    value: 5000,
    helperText: '20% choose this'
  },
  {
    label: '$2,500',
    value: 2500,
    helperText: '45% choose this'
  },
  {
    label: '$1,000',
    value: 1000,
    helperText: '25% choose this'
  },
  {
    label: '$500',
    value: 500,
    helperText: '10% choose this'
  },
];

export function Deductible({ value: valueProp, onSubmit, updating = false }: Props) {
  const [currentValue, setCurrentValue] = React.useState<number>(valueProp);

  const handleNext = React.useCallback(() => {
    onSubmit(currentValue);
  }, [currentValue, onSubmit]);

  return (
    <SectionWrapper
      title="How much would you like your deductible to be?"
      primaryButtonLabel="Next"
      onPrimaryButtonClick={handleNext}
      primaryButtonLoading={updating}
      disableButtons={!currentValue}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          flexGrow: 1,
          gap: { xs: 2, md: 3 },
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <RadioGroup sx={{ gap: { xs: 2, md: 3 }, width: { xs: '100%', md: '420px' } }}>
          {Options.map((option, idx) => (
            <RadioOption
              {...option}
              onSelect={setCurrentValue}
              selected={currentValue === option.value}
              key={idx}
            />
          ))}
        </RadioGroup>
      </Box>
    </SectionWrapper>
  );
}
