import CircleIcon from '@mui/icons-material/Circle';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ReplayIcon from '@mui/icons-material/Replay';
import { Box, Container, IconButton, Tooltip, Typography } from '@mui/material';
import { ContactModal } from 'components/ContactModal';
import { ReactComponent as Logo } from 'images/logo.svg';
import { upperCase } from 'lodash';
import { useCurrentUser } from 'queries';
import { useState } from 'react';
import { WEBSITE_URL } from 'rentr-constants';
import { StartOverModal } from 'views/onboarding/components/StartOverModal';

interface Props {
  allowStartOver?: boolean;
  onStartOver: () => void;
}

export const Navigation = ({ allowStartOver, onStartOver }: Props) => {
  const [showContactModal, setShowContactModal] = useState(false);
  const [showStartOverModal, setShowStartOverModal] = useState(false);
  const { data: currentUser } = useCurrentUser();

  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      height: 70,
      backgroundColor: '#fff',
      borderBottom: '1px solid rgba(0, 0, 0, 0.1)'
    }}>
      <Container maxWidth="xl" sx={{
        color: '#fff',
        alignItems: { xs: 'flex-start', md: 'center' },
        justifyContent: 'space-between',
        display: 'flex',
        gap: 2
      }}>
        <a href={currentUser ? '/home' : WEBSITE_URL}>
          <Logo style={{ display: 'block', height: 34, width: 'auto' }}/>
        </a>
        <Box display="flex" gap={1}>
          {allowStartOver && (
            <Tooltip title="Start over" placement="bottom" arrow>
              <IconButton onClick={() => setShowStartOverModal(true)}>
                <ReplayIcon sx={{ color: 'gray[900]'}} />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Need help?" placement="bottom" arrow>
            <IconButton onClick={() => setShowContactModal(true)}>
              <HelpOutlineIcon sx={{ color: 'gray[900]'}} />
            </IconButton>
          </Tooltip>
          {currentUser && (
            <Box display="flex" alignItems="center" sx={{ ml: 1, gap: 1 }}>
              <CircleIcon color="success" sx={{ fontSize: 12 }} />
              <Typography variant="body1" sx={{ fontSize: 16, fontWeight: 500, color: (t) => t.palette.grey['800'] }}>
                {upperCase(`${currentUser.firstName} ${currentUser.lastName}`)}
              </Typography>
            </Box>
          )}
        </Box>
        <ContactModal
          open={showContactModal}
          onClose={() => setShowContactModal(false)}
        />
        <StartOverModal
          open={showStartOverModal}
          onClose={() => setShowStartOverModal(false)}
          onCancel={() => setShowStartOverModal(false)}
          onStartOver={() => {
            onStartOver();
            setShowStartOverModal(false);
          }}
        />
      </Container>
    </Box>
  );
};
