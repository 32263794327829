import { QueryClient } from '@tanstack/react-query';
import isObject from 'lodash/isObject';

export const queryClient = new QueryClient();

export const NODE_ENV = process.env.NODE_ENV;
export const IS_PROD_ENV = process.env.NODE_ENV === 'production';
export const WEBSITE_URL = process.env.REACT_APP_WEBSITE_URL as string;
export const API_URL = process.env.REACT_APP_API_URL as string;
export const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE as string;
export const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN as string;
export const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID as string;
export const GOOGLE_ANALYTICS_TAG_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_TAG_ID as string;
export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string;
export const SENTRY_ENVIRONMENT = process.env.REACT_APP_SENTRY_ENVIRONMENT as string;
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN as string;
export const FACEBOOK_PIXEL_ID = process.env.REACT_APP_FACEBOOK_PIXEL_ID;

export const SUPPORT_PHONE = '(415) 645-5180';
export const SUPPORT_PHONE_TEL = '4156455180';
export const SUPPORT_EMAIL = 'support@homeflow.me';
export const CA_FAIR_PLAN_WARNING = `Your property has a high risk for wildfire, so this insurer has written this policy with a DIC endorsement. Your property will still be covered, but some risks are excluded (such as wildfire). Homeflow can help you obtain coverage for non-covered risks through a CA Fair Plan. Please call us at ${SUPPORT_PHONE} or email support@homeflow.me to discuss.`;
export const CA_FAIR_PLAN_ACKNOWLEDGEMENT = `Your property has a high risk for wildfire, so this policy is written with a DIC endorsement. This means some risks are excluded (such as wildfire). Homeflow can help you obtain coverage for these non-covered risks through a CA Fair Plan. Please call us at ${SUPPORT_PHONE} or email ${SUPPORT_EMAIL} to discuss.\n\nDo you acknowledge that you will need to obtain a CA Fair Plan to purchase this policy?`;
export const ROOF_FIRE_LIGHTNING_ONLY_WARNING = 'Due to your roof age, this policy is written with the Roof for Fire and Lightning Only Endorsement, limiting coverage to the perils of fire and lightning only for roof and ensuing damage due to roof failure.';
export const ROOF_FIRE_LIGHTNING_ONLY_ACKNOWLEDGEMENT = 'Do you understand that due to the roof age, the roof on your dwelling is only covered for losses caused by the perils of fire and/or lightning, and that there is no coverage for damage to the interior of the dwelling if such damage is due to the failure of the roof caused by a peril other than fire and/or lightning?';

export const FAKE_PROPERTIES = [
  {
    address: '9928 Applegate Rd',
    city: 'Tucson',
    state: 'AZ',
  },
  {
    address: '3214 Colgate Street',
    city: 'Dallas',
    state: 'TX',
  },
  {
    address: '3231 Durango Drive',
    city: 'San Antonio',
    state: 'TX'
  },
  {
    address: '5323 Grand Blvd',
    city: 'Tucson',
    state: 'AZ'
  },
  {
    address: '2232 Indiana Ave',
    city: 'Fort Worth',
    state: 'TX'
  },
  {
    address: '8277 Keller Way',
    city: 'San Francisco',
    state: 'CA'
  }
];

const TITLE = 'Homeflow';
export const META = {
  description: '',
  title: TITLE,
  titleTemplate: `${TITLE} | %s`
};

// All routes used in the application are defined here
export const ROUTES = {
  ROOT: '/',
  ABOUT_US: '/about-us',
  CONTACT: '/contact',
  CUSTOMER_PORTAL: withParentPrefix('/home', {
    policy: '/:id'
  }),
  FORM: '/form',
  LICENSES: '/licenses',
  LANDING_V2: '/landing-v2',
  LOGIN: '/login',
  LOGIN_CALLBACK: '/login-callback',
  NOT_AVAILABLE: '/not-available',
  PRIVACY_POLICY: '/privacy-policy',
  PURCHASE: '/purchase/:id',
  ONBOARDING: '/onboarding',
  TERMS_OF_SERVICE: '/terms-of-service',
  dashboard: withParentPrefix('/dashboard', {
    lead: withParentPrefix('/leads/:id', {
      QUOTE_SHEET: '/quote-sheet',
      PROPERTY_DETAILS: '/property-details',
      HOME_DETAILS: '/home-details',
      CUSTOMER_DATA: '/customer-data',
      ADDITIONAL: '/additional',
      QUOTES: '/quotes',
      POLICY: '/policy',
      HISTORY: '/history'
    })
  })
};

export const insertIdToRoute = (route: string, id: string) => route.replace(':id', id);

interface DefaultRoute {
  ROOT: string;
}

function withParentPrefix<M extends Record<string, any>>(prefix: string, routes: M): M & DefaultRoute {
  const initial: M & DefaultRoute = Object.assign(
    {
      ROOT: prefix
    },
    routes
  );

  return Object.keys(routes).reduce(
    (accumulator: M & DefaultRoute, key: string) =>
      Object.assign(accumulator, {
        [key]: isObject(routes[key]) ? withParentPrefix(prefix, routes[key]) : `${prefix}${routes[key]}`
      }),
    initial
  );
}

export const US_STATES: string[] = [
  'AK',
  'AL',
  'AR',
  'AS',
  'AZ',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'GU',
  'HI',
  'IA',
  'ID',
  'IL',
  'IN',
  'KS',
  'KY',
  'LA',
  'MA',
  'MD',
  'ME',
  'MI',
  'MN',
  'MO',
  'MS',
  'MT',
  'NC',
  'ND',
  'NE',
  'NH',
  'NJ',
  'NM',
  'NV',
  'NY',
  'OH',
  'OK',
  'OR',
  'PA',
  'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VA',
  'VI',
  'VT',
  'WA',
  'WI',
  'WV',
  'WY'
];
