import AddIcon from '@mui/icons-material/Add';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Box, Button, IconButton, TextField, Typography } from '@mui/material';
import { UserRole } from 'dtos';
import { useCurrentUser, useLeadPolicies } from 'queries';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { PolicyGrid, PolicyGridRowItem } from '../components/policy-grid';

export default function CustomerDashboard() {
  const navigate = useNavigate();
  const { data: currentUser } = useCurrentUser();
  const [policies, setPolicies] = React.useState<PolicyGridRowItem[]>([]);
  const { isLoading: _ } = useLeadPolicies(currentUser?.originalLeadId, {
    onSuccess: (data) => {
      setPolicies(data.map((policy) => {
        return {
          id: policy.id,
          status: String(policy.status),
          carrier: policy.carrier.name,
          premium: policy.premium,
          coverageA: +policy.coverages['coverageA'],
          address: `${policy.propertyAddressStreet}${policy.propertyAddressStreet2?.length ? `Unit ${policy.propertyAddressStreet2}` : ''}`,
          city: policy.propertyAddressCity,
          state: policy.propertyAddressState
        };
      }));
    }
  });

  return (
    <React.Fragment>
      <Box display="flex">
        <Box display="flex" sx={{ flexDirection: 'column' }}>
          <Typography variant="h1">Dashboard</Typography>
          <Typography
            variant="subtitle1"
            sx={{ color: (t) => t.palette.grey['800'] }}
          >
            Manage your insurance across your portfolio
          </Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        sx={{ mt: 4, justifyContent: 'space-between', alignItems: 'center' }}
      >
        <Box display="flex" sx={{ width: '30%' }}>
          <TextField
            label="Search"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            placeholder="Address, city, state"
            fullWidth
            size="small"
          />
          <IconButton sx={{ ml: 2 }}>
            <FilterListIcon sx={{ color: (t) => t.palette.grey['900'] }} />
          </IconButton>
        </Box>
        {currentUser?.role !== UserRole.PROPERTY_MANAGER && (
          <Button color="primary" variant="contained" onClick={() => navigate('/onboarding/1')}>
            <AddIcon fontSize="small" />
            New Quote
          </Button>
        )}
      </Box>
      <Box display="flex" sx={{ mt: 4 }}>
        <Box sx={{ height: 400, width: '100%' }}>
          <PolicyGrid rows={policies} />
        </Box>
      </Box>
    </React.Fragment>
  );
}
