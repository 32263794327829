// import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { startCase, upperCase } from 'lodash';
import * as React from 'react';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';

export interface PolicyGridRowItem {
  id?: string;
  address?: string;
  city?: string;
  state?: string;
  status?: string;
  carrier?: string;
  premium?: number;
  coverageA?: number;
}
export interface Props {
  rows?: PolicyGridRowItem[];
}

export function PolicyGrid({ rows = [] }: Props) {
  return (
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          <TableCell sx={{ backgroundColor: 'rgba(0,0,0,0)' }}>Address</TableCell>
          <TableCell sx={{ backgroundColor: 'rgba(0,0,0,0)' }}>City</TableCell>
          <TableCell sx={{ backgroundColor: 'rgba(0,0,0,0)' }}>State</TableCell>
          <TableCell sx={{ backgroundColor: 'rgba(0,0,0,0)' }}>Status</TableCell>
          <TableCell sx={{ backgroundColor: 'rgba(0,0,0,0)' }}>Carrier</TableCell>
          <TableCell sx={{ backgroundColor: 'rgba(0,0,0,0)' }}>Premium</TableCell>
          <TableCell sx={{ backgroundColor: 'rgba(0,0,0,0)' }}>Coverage</TableCell>
          {/* <TableCell sx={{ backgroundColor: 'rgba(0,0,0,0)' }}></TableCell> */}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows
          //   .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((row) => {
            return (
              <TableRow tabIndex={-1} key={row.id}>
                <TableCell>
                  <Link to={`/home/${row.id}`}>
                    <Typography sx={{ color: (t) => t.palette.primary.dark, fontWeight: 400, textDecoration: 'none' }}>
                      {row.address}
                    </Typography>
                  </Link>
                </TableCell>
                <TableCell>
                  {startCase(row.city)}
                </TableCell>
                <TableCell>
                  {upperCase(row.state)}
                </TableCell>
                {/* TODO: fix policy status colors on grid */}
                <TableCell>
                  <Chip color="success" variant="outlined" label={startCase(row.status)} sx={{ backgroundColor: '#D9FEE5', borderRadius: 1, height: 'auto' }} />
                </TableCell>
                <TableCell>
                  {startCase(row.carrier)}
                </TableCell>
                <TableCell>
                  <NumberFormat thousandSeparator="," prefix="$" value={row.premium} displayType="text" />
                </TableCell>
                <TableCell>
                  <NumberFormat thousandSeparator="," prefix="$" value={row.coverageA} displayType="text" />
                </TableCell>
                {/* <TableCell align="right">
                  <IconButton onClick={() => undefined}>
                    <MoreHorizIcon />
                  </IconButton>
                </TableCell> */}
              </TableRow>
            );
          })}
      </TableBody>
    </Table>
  );
}
