export enum UserRole {
  ADMIN = 'admin',
  AGENT = 'agent',
  USER = 'user',
  PROPERTY_MANAGER = 'property-manager'
}

export interface UserDTO {
  id: string;
  originalLeadId?: string;
  firstName?: string;
  lastName?: string;
  email: string;
  phoneNumber: string;
  dateOfBirth: string;
  role: UserRole;
}
