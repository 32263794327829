import { ActivatePolicyDTO, CheckoutStatusResponseDTO, CreatePolicyClientDTO, PolicyDTO } from 'dtos';
import { CreateEndorsementDTO, EndorsementDTO } from 'dtos/endorsement';

import RequestService from './Request';

class _PolicyService {
  getAllPoliciesForLead(leadId: string): Promise<PolicyDTO[]> {
    return RequestService.client.get<PolicyDTO[]>(`/policy/lead/${leadId}`).then((response) => response.data);
  }

  getPolicy(policyId: string): Promise<PolicyDTO> {
    return RequestService.client.get<PolicyDTO>(`/policy/${policyId}`).then((response) => response.data);
  }

  getPolicyDocuments(policyId: string): Promise<any[]> {
    return RequestService.client.get<any[]>(`/policy/${policyId}/documents`).then((response) => response.data);
  }

  downloadPolicyDocument(documentId: string): Promise<{ downloadUrl: string }> {
    return RequestService.client.get<{ downloadUrl: string }>(`/policy-documents/${documentId}`).then((response) => response.data);
  }
  selectQuote(data: CreatePolicyClientDTO): Promise<PolicyDTO> {
    return RequestService.client.post<PolicyDTO>('/policy/select-quote', data).then((response) => response.data);
  }

  requestSigningPolicyLink(policyId: string): Promise<string> {
    return RequestService.client.post<string>(`/policy/${policyId}/sign-documents`).then((response) => response.data);
  }

  checkSigningStatus(policyId: string): Promise<boolean> {
    return RequestService.client.get<boolean>(`/policy/${policyId}/sign-documents`).then((response) => response.data);
  }

  requestPaymentPolicyLink(policyId: string): Promise<string> {
    return RequestService.client.post<string>(`/policy/${policyId}/payment`).then((response) => response.data);
  }

  checkBillingStatus(policyId: string): Promise<CheckoutStatusResponseDTO> {
    return RequestService.client.get<CheckoutStatusResponseDTO>(`/policy/${policyId}/payment`).then((response) => response.data);
  }

  activatePolicy(policyId: string, data: ActivatePolicyDTO): Promise<PolicyDTO> {
    return RequestService.client.post<PolicyDTO>(`/policy/activate/${policyId}`, data).then((response) => response.data);
  }

  signPolicy(policyId: string): Promise<PolicyDTO> {
    return RequestService.client.post<PolicyDTO>(`/policy/sign/${policyId}`).then((response) => response.data);
  }

  payForPolicy(policyId: string): Promise<PolicyDTO> {
    return RequestService.client.post<PolicyDTO>(`/policy/pay/${policyId}`).then((response) => response.data);
  }

  createEndorsement(policyId: string, data: Partial<CreateEndorsementDTO>): Promise<EndorsementDTO> {
    return RequestService.client.post<EndorsementDTO>(`/policy/${policyId}/endorsements`, data).then((response) => response.data);
  }

  deleteEndorsements(): Promise<EndorsementDTO> {
    return RequestService.client.delete('/policy/endorsements');
  }
}

const PolicyService = new _PolicyService();

export { _PolicyService, PolicyService };
