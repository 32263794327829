import { Box, CircularProgress, Container } from '@mui/material';
import { useCurrentUser } from 'queries';
import * as React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { ROUTES } from 'rentr-constants';
import { AuthService } from 'services';

import { Navigation } from './components/navbar';

export default function CustomerPortalView() {
  const { isLoading: loadingUser } = useCurrentUser();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!AuthService.isAuthenticated()) {
      navigate(ROUTES.LOGIN);
    }
  }, [navigate]);

  if (loadingUser) {
    return (
      <Box sx={{ flex: 1, justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <Box
          sx={{
            my: 4,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            flexGrow: 1
          }}
        >
          <CircularProgress />
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ flex: 1, justifyContent: 'center', minHeight: '100vh' }}>
      <Navigation />
      <Container maxWidth="xl" sx={{ mt: 4 }}>
        <Box sx={{ px: 2 }}>
          <Outlet />
        </Box>
      </Container>
    </Box>
  );
}
