import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import GridViewIcon from '@mui/icons-material/GridView';
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';
import { Box, Button, Container, Menu, MenuItem } from '@mui/material';
import { ContactModal } from 'components/ContactModal';
import { ReactComponent as Logo } from 'images/logo.svg';
import { useCurrentUser } from 'queries';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES, WEBSITE_URL } from 'rentr-constants';
import { AuthService, TokensService } from 'services';

interface Props {
  onLogoClickPath?: string;
  alwaysContact?: boolean;
}

export const Navigation = ({ onLogoClickPath: _, alwaysContact }: Props) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const { data: currentUser } = useCurrentUser({ enabled: TokensService.isAuthenticatedInStorage() });
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      height: 70,
      backgroundColor: '#fff',
      borderBottom: '1px solid rgba(0, 0, 0, 0.1)'
    }}>
      <Container sx={{
        color: '#fff',
        alignItems: 'center',
        justifyContent: 'space-between',
        display: 'flex',
        gap: 2
      }}>
        <a href={WEBSITE_URL}>
          <Logo style={{ display: 'block', marginRight: 16, height: 40, width: 'auto' }}/>
        </a>

        {currentUser && !alwaysContact ? (
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button
              sx={(t) => ({
                color: '#fff',
                fontSize: t.typography.body1,
                fontWeight: 'bold'
              })}
              onClick={() => navigate(ROUTES.dashboard.ROOT)}
            >
              <GridViewIcon sx={{ mr: 1 }}/> Dashboard
            </Button>
            <Button
              sx={(t) => ({
                color: '#fff',
                fontSize: t.typography.body1,
                fontWeight: 'bold'
              })}
              onClick={handleClick}
            >
              <AccountCircleIcon sx={{ mr: 1 }}/> Profile
            </Button>
            <Menu
              onClose={handleClose}
              anchorEl={anchorEl}
              open={openMenu}
            >
              <MenuItem disabled>{currentUser.email}</MenuItem>
              <MenuItem onClick={() => AuthService.logout(`${window.location.origin}/login`)}>Logout</MenuItem>
            </Menu>
          </Box>
        ) : (
          <Box>
            <Button
              onClick={() => setOpen(true)}
              sx={(t) => ({
                color: '#fff !important',
                fontSize: t.typography.body1,
                fontWeight: 'bold'
              })}
            >
              <PermPhoneMsgIcon sx={{ mr: 1 }}/> Contact<Box sx={{ display: { xs: 'none', md: 'block' }, ml: '4px' }}>an agent</Box>
            </Button>
          </Box>
        )}

        <ContactModal open={open} onClose={() => setOpen(false)}/>
      </Container>
    </Box>
  );
};
