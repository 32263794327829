import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
  Box,
  Button,
  Chip,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { UserRole } from 'dtos';
import { EndorsementDTO } from 'dtos/endorsement';
import { lowerCase, startCase } from 'lodash';
import { useCurrentUser, usePolicy, usePolicyDocuments } from 'queries';
import * as React from 'react';
import NumberFormat from 'react-number-format';
import { Link, useParams } from 'react-router-dom';
import { PolicyService } from 'services';

import { EndorsementModal } from '../components/endorsement-modal';

export default function CustomerPolicy() {
  const { id } = useParams();
  const policyId = id as string;
  const { data: currentUser } = useCurrentUser();
  const { data: policy } = usePolicy(policyId);
  const { data: documents } = usePolicyDocuments(policyId);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const [endorsementOpen, setEndorsementOpen] = React.useState(false);
  const [pendingEndorsements, setPendingEndorsements] = React.useState<EndorsementDTO[]>([]);

  React.useEffect(() => {
    if (policy) {
      if (policy.endorsements?.length) {
        const pending = policy.endorsements.filter(e => e.status === 'pending');
        setPendingEndorsements(pending);
      }
    }
  }, [policy, setPendingEndorsements]);

  const handleEndorsementOpen = () => {
    setEndorsementOpen(true);
  };

  const handleEndorsementClose = () => {
    setEndorsementOpen(false);
    setAnchorEl(null);
  };

  const handleActionsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDocumentClick = React.useCallback((documentId: string) => {
    PolicyService.downloadPolicyDocument(documentId)
      .then((resp) => {
        if (resp.downloadUrl) {
          window.open(resp.downloadUrl, '_blank');
        }
      })
      .catch((_) => {
        console.error('Failed to download file');
      });
  }, []);

  if (!policy) {
    return <React.Fragment></React.Fragment>;
  }

  return (
    <Box>
      <Link to="/home">
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            mb: 2.5,
            color: (t) => t.palette.primary.dark,
            fontWeight: 500,
            textDecoration: 'none',
            fontSize: 15,
          }}
        >
          <ArrowBackIcon fontSize="small" />
          Dashboard
        </Typography>
      </Link>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" sx={{ flexDirection: 'column', flexGrow: 1 }}>
          <Box display="flex" alignItems="center" gap={2}>
            <Typography variant="h1">{policy.propertyAddressStreet}</Typography>
            <Chip
              color="success"
              variant="outlined"
              label="Active"
              sx={{
                backgroundColor: '#D9FEE5',
                borderRadius: 1,
                height: '22px'
              }}
            />
          </Box>
          <Typography
            variant="subtitle1"
            sx={{
              fontSize: 13,
              fontWeight: 500,
              color: (t) => t.palette.grey['800'],
            }}
          >
            {`${policy.propertyAddressCity}, ${policy.propertyAddressState}`}
          </Typography>
        </Box>
        <Box
          display="flex"
          sx={{ flexDirection: 'column', justifyContent: 'start', flexGrow: 1 }}
        >
          <Box display="flex" justifyContent="end">
            {currentUser?.role !== UserRole.PROPERTY_MANAGER && (
              <Box sx={{ color: (t) => t.palette.grey['900'] }}>
                <Button
                  variant="outlined"
                  color="inherit"
                  onClick={handleActionsClick}
                  sx={{ px: 3 }}
                >
                  Actions
                </Button>
                <Menu
                  onClose={handleMenuClose}
                  anchorEl={anchorEl}
                  open={openMenu}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <MenuItem sx={{ pr: 8 }} disabled>
                    File a claim
                  </MenuItem>
                  <MenuItem sx={{ pr: 8 }} onClick={handleEndorsementOpen}>
                    Modify policy
                  </MenuItem>
                </Menu>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" flexDirection="column">
          <Typography
            variant="subtitle1"
            sx={{
              mt: 2,
              fontSize: 12,
              fontWeight: 500,
              color: (t) => t.palette.grey['800'],
            }}
          >
            Premium
          </Typography>
          <Box display="flex" alignItems="end">
            <Typography variant="h2">
              <NumberFormat
                thousandSeparator=","
                prefix="$"
                value={policy.premium}
                displayType="text"
              />
            </Typography>
            <Typography variant="subtitle1" sx={{ fontSize: 14, ml: 0.5 }}>
              /yr
            </Typography>
          </Box>
        </Box>
        {pendingEndorsements.length
          ? (
            <Box display="flex" alignItems="center" sx={{ mt: 2, mx: 2, px: 2, borderRadius: 4, backgroundColor: '#FFFF8E', gap: 1 }}>
              <ErrorOutlineIcon fontSize="small" />
              <Typography variant="body1" sx={{ fontSize: 16 }}>
                We are working on your request to {lowerCase(startCase(policy.endorsements[0].type.replace('modify', 'change')))}
              </Typography>
            </Box>
          ) : undefined}
      </Box>
      <Box display="flex" gap={10} my={3}>
        {/* Coverages Card */}
        <Box
          display="flex"
          flexDirection="column"
          flexGrow={1}
          sx={{
            maxWidth: '50%',
            backgroundColor: (t) => t.palette.common.white,
            borderRadius: 1,
            border: (t) => `solid 1px ${t.palette.grey['600']}`,
            py: 3,
            px: 4,
          }}
        >
          <Typography variant="h3" sx={{ mb: 2 }}>
            Coverages
          </Typography>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="stretch"
            my={0.75}
          >
            <Typography
              variant="body1"
              sx={{
                width: '50%',
                fontSize: 15,
                color: (t) => t.palette.grey['900'],
              }}
            >
              Coverage A (Dwelling)
            </Typography>
            <Typography
              variant="body1"
              sx={{
                width: '50%',
                fontSize: 15,
                color: (t) => t.palette.grey['900'],
              }}
            >
              <NumberFormat
                thousandSeparator=","
                prefix="$"
                value={+policy.coverages['coverageA']}
                displayType="text"
              />
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="stretch"
            my={0.75}
          >
            <Typography
              variant="body1"
              sx={{
                width: '50%',
                fontSize: 15,
                color: (t) => t.palette.grey['900'],
              }}
            >
              Coverage B (Other Structures)
            </Typography>
            <Typography
              variant="body1"
              sx={{
                width: '50%',
                fontSize: 15,
                color: (t) => t.palette.grey['900'],
              }}
            >
              <NumberFormat
                thousandSeparator=","
                prefix="$"
                value={+policy.coverages['coverageB']}
                displayType="text"
              />
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="stretch"
            my={0.75}
          >
            <Typography
              variant="body1"
              sx={{
                width: '50%',
                fontSize: 15,
                color: (t) => t.palette.grey['900'],
              }}
            >
              Coverage C (Personal Property)
            </Typography>
            <Typography
              variant="body1"
              sx={{
                width: '50%',
                fontSize: 15,
                color: (t) => t.palette.grey['900'],
              }}
            >
              <NumberFormat
                thousandSeparator=","
                prefix="$"
                value={+policy.coverages['coverageC']}
                displayType="text"
              />
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="stretch"
            my={0.75}
          >
            <Typography
              variant="body1"
              sx={{
                width: '50%',
                fontSize: 15,
                color: (t) => t.palette.grey['900'],
              }}
            >
              Coverage D (Loss of Use)
            </Typography>
            <Typography
              variant="body1"
              sx={{
                width: '50%',
                fontSize: 15,
                color: (t) => t.palette.grey['900'],
              }}
            >
              <NumberFormat
                thousandSeparator=","
                prefix="$"
                value={+policy.coverages['coverageD']}
                displayType="text"
              />
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="stretch"
            my={0.75}
          >
            <Typography
              variant="body1"
              sx={{
                width: '50%',
                fontSize: 15,
                color: (t) => t.palette.grey['900'],
              }}
            >
              Coverage E (Personal Liability)
            </Typography>
            <Typography
              variant="body1"
              sx={{
                width: '50%',
                fontSize: 15,
                color: (t) => t.palette.grey['900'],
              }}
            >
              <NumberFormat
                thousandSeparator=","
                prefix="$"
                value={+policy.coverages['coverageE']}
                displayType="text"
              />
            </Typography>
          </Box>
        </Box>
        {/* Policy Card */}
        <Box
          display="flex"
          flexDirection="column"
          flexGrow={1}
          justifyContent="flex-start"
          sx={{
            maxWidth: '50%',
            backgroundColor: (t) => t.palette.common.white,
            borderRadius: 1,
            border: (t) => `solid 1px ${t.palette.grey['600']}`,
            py: 3,
            px: 4,
          }}
        >
          <Typography variant="h3" sx={{ mb: 2 }}>
            Policy
          </Typography>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="stretch"
            my={0.75}
          >
            <Typography
              variant="body1"
              sx={{
                width: '50%',
                fontSize: 15,
                color: (t) => t.palette.grey['900'],
              }}
            >
              Carrier
            </Typography>
            <Typography
              variant="body1"
              sx={{
                width: '50%',
                fontSize: 15,
                color: (t) => t.palette.grey['900'],
              }}
            >
              {policy.carrier.name}
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="stretch"
            my={0.75}
          >
            <Typography
              variant="body1"
              sx={{
                width: '50%',
                fontSize: 15,
                color: (t) => t.palette.grey['900'],
              }}
            >
              Policy Number
            </Typography>
            <Typography
              variant="body1"
              sx={{
                width: '50%',
                fontSize: 15,
                color: (t) => t.palette.grey['900'],
              }}
            >
              {policy.policyNumber}
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="stretch"
            my={0.75}
          >
            <Typography
              variant="body1"
              sx={{
                width: '50%',
                fontSize: 15,
                color: (t) => t.palette.grey['900'],
              }}
            >
              Effective
            </Typography>
            <Typography
              variant="body1"
              sx={{
                width: '50%',
                fontSize: 15,
                color: (t) => t.palette.grey['900'],
              }}
            >
              {dayjs(policy.effectiveDate).format('MMM DD, YYYY')}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box display="flex" gap={4} mt={4} mb={2}>
        <Typography variant="h3" sx={{ color: (t) => t.palette.grey['900'] }}>
          Documents
        </Typography>
      </Box>
      <Box
        display="flex"
        gap={4}
        sx={{ borderTop: (t) => `solid 1px ${t.palette.grey['700']}` }}
      >
        {/* Documents Table */}
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  backgroundColor: 'rgba(0,0,0,0)',
                  color: (t) => t.palette.grey['800'],
                }}
              >
                Name
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: 'rgba(0,0,0,0)',
                  color: (t) => t.palette.grey['800'],
                }}
              >
                Last Updated
              </TableCell>
              <TableCell sx={{ backgroundColor: 'rgba(0,0,0,0)' }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {documents &&
              documents
                //   .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .sort((d1, d2) => d1.createdAt > d2.createdAt ? -1 : 1)
                .map((row) => {
                  return (
                    <TableRow tabIndex={-1} key={row.id}>
                      <TableCell>
                        <Typography
                          sx={{ fontWeight: 400, textDecoration: 'none' }}
                        >
                          {row.fileName}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {dayjs(row.createdAt).format('MM/DD/YYYY hh:mm A')}
                      </TableCell>
                      <TableCell>
                        <Box display="flex" justifyContent="end">
                          <Button
                            variant="text"
                            onClick={() => handleDocumentClick(row.id)}
                          >
                            View
                          </Button>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>
      </Box>
      {/* Endorsement Dialog */}
      <EndorsementModal
        open={endorsementOpen}
        handleClose={handleEndorsementClose}
        policyId={policyId}
        userId={currentUser.id}
        coverages={policy.coverages}
      />
    </Box>
  );
}
