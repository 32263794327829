import { Box, CircularProgress } from '@mui/material';
import { useCurrentUser } from 'queries';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthService, TokensService } from 'services';

const LoginCallbackView = () => {
  const navigate = useNavigate();
  const [error, _setError] = useState<string | null>(null);
  const isChecking = useRef<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);
  const { data: user } = useCurrentUser({ enabled: checked });

  useEffect(() => {
    if (checked && user && TokensService.isAuthenticatedInStorage()) {
      if (user.role === 'agent') {
        navigate('/dashboard');
      } else {
        navigate('/home');
      }
    }
  }, [checked, user, navigate]);

  useEffect(() => {
    if (!isChecking.current && /access_token|id_token|error/.test(location.hash)) {
      isChecking.current = true;

      AuthService.handleAuthentication()
        .then(() => {
          setChecked(true);
        });
    }
  }, [navigate]);

  return (
    <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
      {error ? error : <CircularProgress />}
    </Box>
  );
};

export default LoginCallbackView;
