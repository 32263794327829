import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Box, BoxProps, Button, InputAdornment } from '@mui/material';
import { useCurrencyFieldRendered, useSelectRendered, useTextFieldRendered } from 'components/forms/helpers';
import {
  ConstructionType,
  FixturesAndFinishes,
  InsuranceType,
  LeadDTO,
  LevelOfCoverage,
  Occupancy, OccupancyHomeowner, OccupancyLandlord,
  PropertyType,
  QuoteDeductibleType,
  RentalTime,
  UpdateLeadDTO
} from 'dtos';
import { isString, startCase } from 'lodash';
import * as React from 'react';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { US_STATES } from 'rentr-constants';
import * as yup from 'yup';

export interface Props {
  lead?: Partial<LeadDTO>;
  onSubmit: (data: UpdateLeadDTO) => void;
  isLoading?: boolean;
  submit?: string | JSX.Element;
  containerSx?: BoxProps['sx'];
  hideFields?: Array<keyof FormData>;
}

interface FormData {
  propertyAddressStreet?: string;
  propertyAddressStreet2?: string;
  propertyAddressCity?: string;
  propertyAddressState?: string;
  propertyAddressZipCode?: string;
  rentalIncomePerMonth?: number;
  yearBuilt?: number;
  buildingSize?: number;
  numberOfStories?: number;
  occupancy?: Occupancy;
  underRenovation: boolean;
  levelOfCoverage?: LevelOfCoverage;
  fixturesAndFinishes?: FixturesAndFinishes;
  insuranceType?: InsuranceType;
  rentalTime?: RentalTime;
  propertyType?: PropertyType;
  constructionType?: ConstructionType;
  deductibleType?: QuoteDeductibleType;
  deductible: number;
}

const schema = yup.object().shape({
  propertyAddressStreet: yup.string().required(),
  propertyAddressStreet2: yup.string().nullable(),
  propertyAddressCity: yup.string().required(),
  propertyAddressState: yup.string().required(),
  propertyAddressZipCode: yup.string().required().length(5, 'Zip Code must have 5 digits'),

  // Number
  rentalIncomePerMonth: yup.number().nullable()
    .when('insuranceType', {
      is: InsuranceType.LANDLORD,
      then: yup.number().transform((value) => (isNaN(value) ? undefined : value)).required('Income is required').min(0, 'Minimum 0')
    }),
  yearBuilt: yup.number().transform((value) => (isNaN(value) ? undefined : value)).required('Year build is required').min(1500, 'Please enter a correct year').max(new Date().getFullYear(), `Max current year - ${new Date().getFullYear()}`),
  buildingSize: yup.number().transform((value) => (isNaN(value) ? undefined : value)).required('Building size is required').min(1, 'Minimum 1'),
  numberOfStories: yup.number().transform((value) => (isNaN(value) ? undefined : value)).required('Number of stories is required').min(1, 'Minimum 1'),
  deductible: yup.number().required(),

  // Enums
  occupancy: yup.string().required(),
  insuranceType: yup.string().required(),
  rentalTime: yup.string().nullable().when('insuranceType', { is: InsuranceType.LANDLORD, then: yup.string().required() }),
  propertyType: yup.string().required(),
  fixturesAndFinishes: yup.string().nullable(), // TODO: Find conditions to validate
  constructionType: yup.string().nullable(),
  deductibleType: yup.string().nullable(),

  // Booleans
  underRenovation: yup.boolean().required()
});

export const PropertyDetailsForm = ({ lead, hideFields, isLoading, onSubmit, submit, containerSx }: Props) => {
  const { control, handleSubmit, formState, watch } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      propertyAddressStreet: lead?.propertyAddressStreet,
      propertyAddressStreet2: lead?.propertyAddressStreet2,
      propertyAddressCity: lead?.propertyAddressCity,
      propertyAddressState: lead?.propertyAddressState,
      propertyAddressZipCode: lead?.propertyAddressZipCode,
      yearBuilt: lead?.yearBuilt,
      buildingSize: lead?.buildingSize,
      numberOfStories: lead?.numberOfStories,
      underRenovation: lead?.underRenovation || false,
      occupancy: lead?.occupancy,
      fixturesAndFinishes: lead?.fixturesAndFinishes,
      levelOfCoverage: lead?.levelOfCoverage,
      insuranceType: lead?.insuranceType,
      rentalIncomePerMonth: lead?.rentalIncomePerMonth,
      rentalTime: lead?.rentalTime,
      propertyType: lead?.propertyType,
      constructionType: lead?.constructionType,
      deductibleType: lead?.deductibleType,
      deductible: lead?.deductible
    }
  });

  const insuranceType = watch('insuranceType');
  const renderTextField = useTextFieldRendered(control, formState.errors);
  const renderSelect = useSelectRendered(control, formState.errors);
  const renderCurrencyField = useCurrencyFieldRendered(control, formState.errors);
  const shouldHide = useCallback((key: keyof FormData) => hideFields?.includes(key), [hideFields]);
  const implicitMultiUnit = lead?.propertyAddressStreet2;

  return (
    <form onSubmit={handleSubmit(onSubmit, (e) => console.log(e))} style={{ width: '100%' }}>
      <Box
        sx={{
          gap: (t) => t.spacing(2),
          gridTemplateColumns: {
            sm: '1fr',
            md: '1fr 1fr'
          },
          display: 'grid',
          mb: 6,
          ...containerSx
        }}
      >
        {!shouldHide('insuranceType') && renderSelect(Object.values(InsuranceType).map(v => ({
          value: v,
          label: startCase(v)
        })), 'insuranceType', 'Property Ownership', { type: 'select' })}

        {/* Address */}
        {!shouldHide('propertyAddressStreet') && renderTextField('propertyAddressStreet', 'Street')}
        {!shouldHide('propertyAddressStreet2') && renderTextField('propertyAddressStreet2', 'Unit', { required: false })}

        {!shouldHide('propertyAddressCity') && renderTextField('propertyAddressCity', 'City')}

        {!shouldHide('propertyAddressState') && renderSelect(Object.values(US_STATES).map(v => ({
          value: v,
          label: startCase(v)
        })), 'propertyAddressState', 'State')}
        {!shouldHide('propertyAddressZipCode') && renderTextField('propertyAddressZipCode', 'Zip Code')}

        {/* Rental */}
        {insuranceType === InsuranceType.LANDLORD && !shouldHide('rentalTime') && renderSelect(Object.values(RentalTime).map(v => ({
          value: v,
          label: v === RentalTime.SHORT_TERM ? `${startCase(v)} (AirBnb, VRBO, etc.)` : startCase(v)
        })), 'rentalTime', 'How long do you rent for', { type: 'select' })}
        {insuranceType === InsuranceType.LANDLORD && !shouldHide('rentalIncomePerMonth') && renderCurrencyField('rentalIncomePerMonth', 'Rental Income Per Month')}

        {!shouldHide('occupancy') && renderSelect(Object.values(insuranceType === InsuranceType.LANDLORD ? OccupancyLandlord : OccupancyHomeowner).map(v => ({
          value: v,
          label: startCase(v)
        })), 'occupancy', 'Occupancy')}
        {!shouldHide('propertyType') && renderSelect(Object.values(PropertyType).map(v => ({
          value: v,
          label: v === PropertyType.OTHER ? 'I don\'t know' : startCase(v)
        })), 'propertyType', 'Property Type', { type: 'select' })}

        {!shouldHide('fixturesAndFinishes') && renderSelect(Object.values(FixturesAndFinishes).map(v => ({
          value: v,
          label: startCase(v)
        })), 'fixturesAndFinishes', 'Fixtures and finishes', { type: 'select' })}

        {!shouldHide('yearBuilt') && renderTextField('yearBuilt', 'Year Built', { type: 'number', inputProps: { min: 0 }, })}

        {!shouldHide('buildingSize') && renderTextField('buildingSize', `${implicitMultiUnit ? 'Unit' : 'Building'} Size`, {
          type: 'number',
          inputProps: { min: 1 },
          InputProps: {
            endAdornment: <InputAdornment position="end">sqft</InputAdornment>
          }
        })}
        {!shouldHide('numberOfStories') && renderTextField('numberOfStories', `Number of Stories${implicitMultiUnit ? ' (Unit)' : ''}`, { type: 'number', inputProps: { min: 1 } })}

        {!shouldHide('levelOfCoverage') && renderSelect(Object.values(LevelOfCoverage).map(v => ({
          value: v,
          label: startCase(v)
        })), 'levelOfCoverage', 'Level of Coverage', { type: 'select' })}

        {!shouldHide('constructionType') && renderSelect(Object.values(ConstructionType).map(v => ({
          value: v,
          label: v === ConstructionType.OTHER ? 'I don\'t know' : startCase(v)
        })), 'constructionType', 'Construction Type', { type: 'select' })}

        {!shouldHide('underRenovation') && renderSelect([{ label: 'Yes', value: true }, {
          label: 'No',
          value: false
        }], 'underRenovation', 'Under Renovation?', { type: 'select' })}

        {!shouldHide('deductible') && renderSelect([500, 1000, 2500, 5000].map(v => ({
          value: v,
          label: `$${v.toString()}`
        })), 'deductible', 'Desired Deductible')}
      </Box>

      {submit && !isString(submit) ? submit : (
        <Button
          sx={{ width: 200, margin: '0 auto', display: 'block' }}
          type="submit"
          disabled={isLoading}
          variant="contained"
        >
          {isLoading ? 'Loading...' : submit || 'Save'}
        </Button>
      )}
    </form>
  );
};
