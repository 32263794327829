import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { ActivatePolicyDTO, CheckoutStatusResponseDTO, CreatePolicyClientDTO, PolicyDTO } from 'dtos';
import { CreateEndorsementDTO, EndorsementDTO } from 'dtos/endorsement';
import { LEAD_KEY } from 'queries/useLead';
import { queryClient } from 'rentr-constants';
import { PolicyService } from 'services';

export const POLICIES_KEY = 'policies';
export const POLICY_KEY = 'policy';

export const useLeadPolicies = (leadId: string, options?: UseQueryOptions<PolicyDTO[]>) =>
  useQuery<PolicyDTO[]>([POLICIES_KEY, leadId], () => PolicyService.getAllPoliciesForLead(leadId), {
    ...options,
  });

export const usePolicy = (policyId: string, options?: UseQueryOptions<PolicyDTO>) =>
  useQuery<PolicyDTO>([POLICY_KEY, policyId], () => PolicyService.getPolicy(policyId), {
    ...options,
  });

export const usePolicyDocuments = (policyId: string, options?: UseQueryOptions<PolicyDTO>) =>
  useQuery<any>([POLICY_KEY, policyId, 'documents'], () => PolicyService.getPolicyDocuments(policyId), {
    ...options,
  });

export const useCreatePolicyClient = (options?: UseMutationOptions<PolicyDTO, AxiosError, CreatePolicyClientDTO>) =>
  useMutation((data) => PolicyService.selectQuote(data), {
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries([data.leadId]);

      if (options?.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    }
  });

export const usePayForPolicyAgent = (policyId: string, options?: UseMutationOptions<PolicyDTO, AxiosError, void>) =>
  useMutation(() => PolicyService.payForPolicy(policyId), {
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries([POLICIES_KEY, data.leadId]);

      if (options?.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    }
  });

export const useSignPolicyAgent = (policyId: string, options?: UseMutationOptions<PolicyDTO, AxiosError, void>) =>
  useMutation(() => PolicyService.signPolicy(policyId), {
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries([POLICIES_KEY, data.leadId]);

      if (options?.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    }
  });

export const useActivatePolicy = (options?: UseMutationOptions<PolicyDTO, AxiosError, { data: ActivatePolicyDTO, id: string }>) =>
  useMutation(({ data, id}) => PolicyService.activatePolicy(id, data), {
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries([POLICIES_KEY]);
      await queryClient.invalidateQueries([POLICY_KEY]);
      await queryClient.invalidateQueries([LEAD_KEY, data.leadId]);

      if (options?.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    }
  });

export const useRequestSigningPolicyLink = (options?: UseMutationOptions<string, AxiosError, string>) =>
  useMutation((data) => PolicyService.requestSigningPolicyLink(data), {
    ...options,
  });

export const useCheckPolicySigningStatus = (policyId: string, options?: UseQueryOptions<boolean>) =>
  useQuery<boolean>(
    ['signing-check', policyId],
    () => PolicyService.checkSigningStatus(policyId),
    options
  );

export const useRequestPaymentPolicyLink = (options?: UseMutationOptions<string, AxiosError, string>) =>
  useMutation((data) => PolicyService.requestPaymentPolicyLink(data), {
    ...options
  });

export const useCheckPolicyBillingStatus = (policyId: string, options?: UseQueryOptions<CheckoutStatusResponseDTO>) =>
  useQuery<CheckoutStatusResponseDTO>(['billing-check', policyId], () => PolicyService.checkBillingStatus(policyId), options);

export const useCreateEndorsement = (options?: UseMutationOptions<EndorsementDTO, AxiosError, {  policyId: string, data: Partial<CreateEndorsementDTO> }>) =>
  useMutation(({ policyId, data}) => PolicyService.createEndorsement(policyId, data), {
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries([POLICY_KEY]);
      if (options?.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    }
  });