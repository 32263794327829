import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AddAdditionalInsuredRequest, AddCoverageTypeRequest, EndorsementRequestBodyType, EndorsementType, ModifyDeductibleRequest, ModifyOccupancyStatusRequest, OtherEndorsementRequest } from 'dtos/endorsement';
import { startCase } from 'lodash';
import { useCreateEndorsement } from 'queries';
import * as React from 'react';

const EndorsementOptions = Object.values(EndorsementType).map((v) => ({
  label: startCase(v.replace('modify', 'change').replace('type', '')),
  value: v,
}));

enum AdditionalInsuredType {
  Owner = 'owner',
  Lender = 'lender',
  PropertyManager = 'property-manager'
}

const AdditionalInsuredTypeOptions = Object.values(AdditionalInsuredType).map((v) => ({
  label: startCase(v),
  value: v,
}));

enum AdditionalCoverageType {
  ServiceLine = 'service-line',
  WaterBackup = 'water-backup',
  EquipmentBreakdown = 'equipment-breakdown',
  Earthquake = 'earthquake',
  AnimalLiability = 'animal-liability',
}

const AdditionalCoverageTypeOptions = Object.values(AdditionalCoverageType).map((v) => ({
  label: startCase(v),
  value: v,
}));

enum OccupancyStatusType {
  TenantOccupied = 'tenant-occupied',
  OwnerOccupied = 'owner-occupied',
  Renovation = 'renovation',
  Vacant = 'vacant'
}

const OccupancyStatusTypeOptions = Object.values(OccupancyStatusType).map((v) => ({
  label: startCase(v),
  value: v,
}));

const DeductibleAmount: Record<string, number> = {
  '$1,000': 1000,
  '$2,500': 2500,
  '$5,000': 5000,
  '$10,000': 10000,
};

const DeductibleAmountOptions = Object.keys(DeductibleAmount).map((k) => ({
  label: k,
  value: DeductibleAmount[k],
}));

export interface EndorsementModalProps {
  open: boolean;
  handleClose: () => void;
  policyId: string;
  userId: string;
  coverages: Record<string, unknown>;
}

export function EndorsementModal({
  open,
  handleClose: handleCloseProp,
  policyId,
  userId,
  // coverages
}: EndorsementModalProps) {
  const [endorsementOption, setEndorsementOption] =
    React.useState<EndorsementType>();
  const [error, setError] = React.useState('');

  const [additionalInsuredFirstName, setAdditionalInsuredFirstName] =
    React.useState('');
  const [additionalInsuredLastName, setAdditionalInsuredLastName] =
    React.useState('');
  const [additionalInsuredDateOfBirth, setAdditionalInsuredDateOfBirth] =
    React.useState('');
  const [additionalInsuredType, setAdditionalInsuredType] =
    React.useState<AdditionalInsuredType>();
  const [additionalCoverageType, setAdditionalCoverageType] =
    React.useState<AdditionalCoverageType>();
  const [occupancyStatusType, setOccupancyStatusType] =
    React.useState<OccupancyStatusType>();
  const [newDeductible, setNewDeductible] =
    React.useState<number>();
  const [otherEndorsementDescription, setOtherEndorsementDescription] =
    React.useState('');

  const { mutate: createEndorsement, isLoading: submitting } =
    useCreateEndorsement({
      onSuccess: handleCloseProp,
      onError: () => setError('Please fill out all required fields'),
    });

  const getAdditionalInsuredRequestBody: () => AddAdditionalInsuredRequest = React.useCallback(() => {
    const request = new AddAdditionalInsuredRequest();
    request.firstName = additionalInsuredFirstName;
    request.lastName = additionalInsuredLastName;
    request.dateOfBirth = additionalInsuredDateOfBirth;
    request.type = additionalInsuredType;
    return request;
  }, [additionalInsuredFirstName, additionalInsuredLastName, additionalInsuredDateOfBirth, additionalInsuredType]);

  const getAdditionalCoverageRequestBody: () => AddCoverageTypeRequest = React.useCallback(() => {
    const request = new AddCoverageTypeRequest();
    request.coverageType = additionalCoverageType;
    return request;
  }, [additionalCoverageType]);

  const getUpdateOccupancyStatusRequestBody: () => ModifyOccupancyStatusRequest = React.useCallback(() => {
    const request = new ModifyOccupancyStatusRequest();
    request.occupancyStatus = occupancyStatusType;
    return request;
  }, [occupancyStatusType]);

  const getUpdateDeductibleRequestbody: () => ModifyDeductibleRequest = React.useCallback(() => {
    const request = new ModifyDeductibleRequest();
    request.deductible = newDeductible;
    return request;
  }, [newDeductible]);

  const getOtherEndorsementRequestBody: () => OtherEndorsementRequest = React.useCallback(() => {
    const request = new OtherEndorsementRequest();
    request.description = otherEndorsementDescription;
    return request;
  }, [otherEndorsementDescription]);

  const handleEndorsementSubmit = React.useCallback(() => {
    let requestBody: EndorsementRequestBodyType;
    switch (endorsementOption) {
      case EndorsementType.AddAdditionalInsured:
        requestBody = getAdditionalInsuredRequestBody();
        break;
      case EndorsementType.AddCoverageType:
        requestBody = getAdditionalCoverageRequestBody();
        break;
      case EndorsementType.ModifyDeductible:
        requestBody = getUpdateDeductibleRequestbody();
        break;
      case EndorsementType.ModifyOccupancyStatus:
        requestBody = getUpdateOccupancyStatusRequestBody();
        break;
      case EndorsementType.Other:
        requestBody = getOtherEndorsementRequestBody();
        break;
      default:
        break;
    }
    createEndorsement({
      policyId,
      data: { userId, policyId, type: endorsementOption, requestBody },
    });
  }, [createEndorsement, policyId, userId, endorsementOption, getAdditionalInsuredRequestBody, getAdditionalCoverageRequestBody, getUpdateDeductibleRequestbody, getUpdateOccupancyStatusRequestBody, getOtherEndorsementRequestBody]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        setEndorsementOption(null);
        handleCloseProp();
      }}
      maxWidth="sm"
      fullWidth
      sx={{ my: 4 }}
    >
      <DialogTitle sx={{ px: 4, mt: 2 }}>
        <Typography variant="h2">Modify Policy</Typography>
      </DialogTitle>
      <DialogContent sx={{ px: 4, pt: 4 }}>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel variant="outlined">
            What would you like to change?
          </InputLabel>
          <Select
            variant="outlined"
            onChange={(e) =>
              setEndorsementOption(e.target.value as EndorsementType)
            }
            value={endorsementOption}
            id="endorsement-request-type"
            label="What would you like to change?"
            required
          >
            {EndorsementOptions
              .sort((o1, o2) => o1.label > o2.label ? 1 : -1)
              .map((option, optionIdx) => (
                <MenuItem
                  key={`endorsement-option-${optionIdx}`}
                  value={option.value}
                >
                  {option.label}
                </MenuItem>
              ))
            }
          </Select>
        </FormControl>
        <Divider />
        {endorsementOption === EndorsementType.AddAdditionalInsured && (
          <>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <TextField
                margin="dense"
                label="First Name"
                type="text"
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                value={additionalInsuredFirstName}
                onChange={(e) => setAdditionalInsuredFirstName(e.target.value)}
                sx={{ mt: 2 }}
              />
            </FormControl>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <TextField
                margin="dense"
                label="Last Name"
                type="text"
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                value={additionalInsuredLastName}
                onChange={(e) => setAdditionalInsuredLastName(e.target.value)}
                sx={{ mt: 2 }}
              />
            </FormControl>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  onChange={(e) => setAdditionalInsuredDateOfBirth(e)}
                  value={additionalInsuredDateOfBirth || null}
                  label={'Date of Birth'}
                  inputFormat="MM/DD/YYYY"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="MM/DD/YYYY"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{ width: '100%', ...params.style }}
                      sx={{ my: 2}}
                    />
                  )}
                />
              </LocalizationProvider>
            </FormControl>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel id="additional-insured-role-label" variant="outlined">
                What is their role?
              </InputLabel>
              <Select
                label="What is their role?"
                id="additional-insured-role"
                variant="outlined"
                value={additionalInsuredType}
                onChange={(e) =>
                  setAdditionalInsuredType(e.target.value as AdditionalInsuredType)
                }
              >
                {AdditionalInsuredTypeOptions.map((option, optionIdx) => (
                  <MenuItem
                    key={`additional-insured-type-option-${optionIdx}`}
                    value={option.value}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        )}
        {endorsementOption === EndorsementType.AddCoverageType && (
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="additional-coverage-type-label" variant="outlined">
              What coverage would you like to add?
            </InputLabel>
            <Select
              label="What coverage would you like to add?"
              id="additional-coverage-type"
              variant="outlined"
              value={additionalCoverageType}
              onChange={(e) =>
                setAdditionalCoverageType(e.target.value as AdditionalCoverageType)
              }
            >
              {AdditionalCoverageTypeOptions.map((option, optionIdx) => (
                <MenuItem
                  key={`additional-coverage-type-option-${optionIdx}`}
                  value={option.value}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {endorsementOption === EndorsementType.ModifyDeductible && (
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="deductible-amount-label" variant="outlined">
              What should the new deductible be?
            </InputLabel>
            <Select
              label="What should the new deductible be?"
              id="deductible-amount"
              variant="outlined"
              value={newDeductible}
              onChange={(e) =>
                setNewDeductible(+e.target.value)
              }
            >
              {DeductibleAmountOptions.map((option, optionIdx) => (
                <MenuItem
                  key={`deductible-amount-option-${optionIdx}`}
                  value={option.value}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {endorsementOption === EndorsementType.ModifyOccupancyStatus && (
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="occupancy-status-type-label" variant="outlined">
              What is the current occupancy status?
            </InputLabel>
            <Select
              label="What is the current occupancy status?"
              id="occupancy-status-type"
              variant="outlined"
              value={occupancyStatusType}
              onChange={(e) =>
                setOccupancyStatusType(e.target.value as OccupancyStatusType)
              }
            >
              {OccupancyStatusTypeOptions.map((option, optionIdx) => (
                <MenuItem
                  key={`occupancy-status-type-option-${optionIdx}`}
                  value={option.value}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {/* {endorsementOption === EndorsementType.ModifyCoverageAmount && (
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="existing-coverage-type-label" variant="outlined">
              Which coverage do you want to change?
            </InputLabel>
            <Select
              label="Which coverage do you want to change?"
              id="existing-coverage-type"
              variant="outlined"
              value={occupancyStatusType}
              onChange={(e) =>
                setOccupancyStatusType(e.target.value as OccupancyStatusType)
              }
            >
              {Object.keys(coverages).map((coverageKey, coverageIdx) => (
                <MenuItem
                  key={`existing-coverage-type-${coverageIdx}`}
                  value={coverageKey}
                >
                  {startCase(coverageKey)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )} */}
        {endorsementOption === EndorsementType.Other && (
          <TextField
            margin="dense"
            id="endorsement-details"
            label="Please tell us more"
            type="text"
            value={otherEndorsementDescription}
            onChange={(e) => setOtherEndorsementDescription(e.target.value)}
            fullWidth
            variant="filled"
            InputLabelProps={{
              shrink: true,
            }}
            multiline
            rows={3}
            sx={{ mt: 2 }}
          />
        )}
        {error?.length ? (
          <Typography
            variant="subtitle2"
            sx={{ mt: 1, color: (t) => t.palette.error.main }}
          >
            {error}
          </Typography>
        ) : undefined}
      </DialogContent>
      <DialogActions sx={{ px: 4, pb: 4, color: (t) => t.palette.grey['800'] }}>
        <Button
          variant="outlined"
          disabled={submitting}
          color="inherit"
          onClick={() => {
            setEndorsementOption(null);
            handleCloseProp();
          }}
          sx={{ width: 120, height: '100%' }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={!endorsementOption}
          onClick={handleEndorsementSubmit}
          sx={{ width: 120 }}
        >
          {submitting ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            'Submit'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
