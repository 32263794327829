import 'views/onboarding/styles.css';

import { Box, Typography } from '@mui/material';
import useAnalytics from 'analytics/useAnalytics';
import { AxiosError } from 'axios';
import { CreateLeadDTO, InsuranceType, LeadDTO, LeadStatus, OccupancyHomeowner, OccupancyLandlord, RentalTime, UpdateLeadDTO } from 'dtos';
import { AddressDto } from 'dtos/address';
import * as _ from 'lodash';
import { useCurrentUser } from 'queries';
import { useCreateLead, useUpdateLead } from 'queries/useLead';
import * as React from 'react';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';
import { Route, Routes } from 'react-router';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import useState from 'react-usestateref';
import { FACEBOOK_PIXEL_ID, ROUTES } from 'rentr-constants';
import { FCC } from 'types';
import { Navigation } from 'views/onboarding/components/Navigation';
import { ContactInfo } from 'views/onboarding/sections/ContactInfo';
import { Deductible } from 'views/onboarding/sections/Deductible';
import { Discounts } from 'views/onboarding/sections/Discounts';
import { EffectiveDate } from 'views/onboarding/sections/EffectiveDate';
import { End } from 'views/onboarding/sections/End';
import { FireDetectionFollowUp } from 'views/onboarding/sections/FireDetectionFollowUp';
import { FirstLastDob } from 'views/onboarding/sections/FirstLastDob';
import { KindOfInsuranceSection } from 'views/onboarding/sections/KindOfInsurance';
import { LevelOfCoverage } from 'views/onboarding/sections/LevelOfCoverage';
import { MailingAddress } from 'views/onboarding/sections/MailingAddress';
import { PreviousClaims } from 'views/onboarding/sections/PreviousClaims';
import { PropertyAddress } from 'views/onboarding/sections/PropertyAddress';
import { PropertyConditions } from 'views/onboarding/sections/PropertyConditions';
import { PropertyData } from 'views/onboarding/sections/PropertyData';
import { PropertyTypeSection } from 'views/onboarding/sections/PropertyType';
import { PropertyUse } from 'views/onboarding/sections/PropertyUse';
import { RedFlags } from 'views/onboarding/sections/RedFlags';
import { ReferralSource } from 'views/onboarding/sections/ReferralSource';
import { RentalTimeline } from 'views/onboarding/sections/RentalTimeline';
import { RentPerMonth } from 'views/onboarding/sections/RentPerMonth';
import { WhoIsGettingInsuredSection } from 'views/onboarding/sections/WhoIsGettingInsured';
import { WhyAreYouHere } from 'views/onboarding/sections/WhyAreYouHere';

export const OnboardingView: FCC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { data: currentUser } = useCurrentUser();
  const [lead, setLead, leadRef] = useState<Partial<LeadDTO>>({});
  const [updating, setUpdating] = React.useState<boolean>(false);
  const [displayLocation, setDisplayLocation] = React.useState(location);
  const [transitionStage, setTransistionStage] = React.useState('fadeIn');
  const questionIndexRef = React.useRef<number>(0);
  const [_error, setError] = React.useState<string>();
  const [allowStartOver, setAllowStartOver] = React.useState(true);
  const { identifyUser, trackLeadCaptured } = useAnalytics();

  const getNextQuestion = React.useCallback(() => {
    const { insuranceType, hasFireDetectionSystem } = leadRef.current;
    switch (questionIndexRef.current) {
      case 4:
        if (currentUser && currentUser.role === 'user') {
          setLead(lead => ({ ...lead, firstName: currentUser.firstName, lastName: currentUser.lastName, birthDate: currentUser.dateOfBirth }));
        }
        return 5;
      // Property Address finished
      case 6:
        if (insuranceType === InsuranceType.LANDLORD) {
          // Skip Property Use, Go to Rental Timeline
          return 8;
        } else {
          // Property Use
          return 7;
        }
      // Property Use finished
      case 7:
        // Skip Rental Timeline (7) and Rental Income (8)
        return 10;
      // Discounts
      case 12:
        if (hasFireDetectionSystem) {
          return 13;
        } else {
          // skip fire detection follow up
          return 14;
        }
      case 19:
        if (currentUser && currentUser.role === 'user') {
          setLead(lead => ({ ...lead, email: currentUser.email, phoneNumber: currentUser.phoneNumber }));
        }
        return 20;
      default:
        return questionIndexRef.current + 1;
    }
  }, [questionIndexRef, leadRef, currentUser, setLead]);

  const handleLeadUpdateSuccess = React.useCallback((updatedLead: LeadDTO) => {
    const created = !!leadRef.current?.createdAt;
    const submitted = !!leadRef.current?.email?.length;
    setLead(lead => ({...lead, ...updatedLead}));
    const { id, firstName, lastName, birthDate, propertyAddressState } = updatedLead;

    // HACK - route FL leads to not-available
    if (propertyAddressState === 'FL') {
      navigate(ROUTES.NOT_AVAILABLE);
      setUpdating(false);
      return;
    }

    if (!created && questionIndexRef.current === 6) {
      // Segement Identify
      // 5. first, last, dob, address submitted
      identifyUser(id, firstName, lastName, birthDate);
      ReactGA.event('prospect_created');
    } else if (questionIndexRef.current === 20) {
      if (!submitted) {
        // Google Analytics Conversion
        ReactGA.event('quotes_requested');
        // Facebook Ads Conversion
        ReactPixel.trackSingle(FACEBOOK_PIXEL_ID, 'Lead');
      }
      // - lead
      // Segment Track Event
      const { email, phoneNumber } = updatedLead;
      trackLeadCaptured(email, phoneNumber);
      // Google Analytics Conversion
      ReactGA.event('quotes_requested');
      // Facebook Ads Conversion
      ReactPixel.trackSingle(FACEBOOK_PIXEL_ID, 'Lead');
    }

    const nextQuestionIndex = getNextQuestion();
    navigate(`/onboarding/${nextQuestionIndex}`);
    setUpdating(false);
  }, [identifyUser, leadRef, trackLeadCaptured, setLead, navigate, setUpdating, getNextQuestion]);

  const handleLeadUpdateError = React.useCallback((error: AxiosError) => {
    console.error('Failed to update lead', error.message);
    setUpdating(false);
    setError('Something went wrong, please try again later.');
  }, [setError]);

  const { mutate: createLead } = useCreateLead({
    onSuccess: handleLeadUpdateSuccess,
    onError: handleLeadUpdateError
  });

  const { mutate: updateLeadOnServer } = useUpdateLead(leadRef.current.id, {
    onSuccess: handleLeadUpdateSuccess,
    onError: handleLeadUpdateError
  });

  const mockUpdate = React.useCallback((updatedLeadValues: Partial<LeadDTO>) => {
    setLead(updatedLeadValues);
    const nextQuestionIndex = getNextQuestion();
    navigate(`/onboarding/${nextQuestionIndex}`);
    setUpdating(false);
  }, [setLead, setUpdating, getNextQuestion, navigate]);

  const delay = React.useCallback((func: () => void, delayInMs: number = 500) => setTimeout(() => func(), delayInMs), []);

  const submitSection = React.useCallback((values: Partial<LeadDTO>, questionIndex: number) => {
    const created = !!leadRef.current?.createdAt;
    setUpdating(true);
    questionIndexRef.current = questionIndex;
    const stagedChanges = _.omit({ ...leadRef.current, ...values }, ['createdById', 'createdAt', 'id', 'updatedAt']);
    if (created) {
      delay(() => updateLeadOnServer(stagedChanges as UpdateLeadDTO));
    } else {
      if (questionIndex === 6) {
        delay(() => createLead(stagedChanges as CreateLeadDTO));
      } else {
        delay(() => mockUpdate(stagedChanges));
      }
    }
  }, [setUpdating, questionIndexRef, leadRef, createLead, updateLeadOnServer, mockUpdate, delay]);

  React.useEffect(
    function FadeOut() {
      if (location.pathname !== displayLocation.pathname) {
        if (location.pathname !== '1') setTransistionStage('fadeOut');
      }
      // HACK:  disable start over after contact info (19)
      if (+(location.pathname.split('/'))[2] > 19) {
        setAllowStartOver(false);
      } else {
        setAllowStartOver(true);
      }
    },
    [location, displayLocation, setTransistionStage]
  );

  const startOver = React.useCallback(() => {
    setLead({});
    navigate('/onboarding/1');
  }, [setLead, navigate]);

  return (
    <Box sx={{ flex: 1, justifyContent: 'center', minHeight: '100vh' }}>
      <Navigation allowStartOver={allowStartOver} onStartOver={startOver} />
      <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, alignItems: 'center', justifyContent: 'center', py: { xs: 3, md: 6 } }}>
        <Box sx={{ maxWidth: { xs: '90vw', md: 1200 } }}>
          <div
            className={`${transitionStage}`}
            onAnimationEnd={() => {
              if (transitionStage === 'fadeOut') {
                setTransistionStage('fadeIn');
                setDisplayLocation(location);
              }
            }}
          >
            <Routes location={displayLocation} key={displayLocation.pathname}>
              <Route path="/" element={<Navigate to="1" replace/>}/>
              <Route path="1" element={
                <KindOfInsuranceSection
                  value={lead.insuranceType}
                  onSubmit={(v) => submitSection({ insuranceType: v }, 1)}
                  updating={updating}
                />
              } />
              <Route path="2" element={
                <WhyAreYouHere
                  value={lead.reason}
                  onContinue={(reason) => submitSection({ reason }, 2)}
                  updating={updating}
                />
              } />
              <Route path="3" element={
                <PropertyTypeSection
                  value={lead.propertyType}
                  onSubmit={(v) => submitSection({ propertyType: v }, 3)}
                  updating={updating}
                />
              } />
              <Route path="4" element={
                <WhoIsGettingInsuredSection
                  value={lead.insuranceHolder}
                  onSubmit={(v) => submitSection({ insuranceHolder: v }, 4)}
                  updating={updating}
                />
              } />
              <Route path="5" element={
                <FirstLastDob
                  insuranceHolder={lead.insuranceHolder}
                  companyName={lead.companyName}
                  firstName={lead.firstName}
                  lastName={lead.lastName}
                  birthDate={lead.birthDate}
                  additionalInsured={lead.additionalInsured}
                  additionalInsuredFirstName={lead.additionalInsuredFirstName}
                  additionalInsuredLastName={lead.additionalInsuredLastName}
                  additionalInsuredDateOfBirth={lead.additionalInsuredDateOfBirth}
                  onSubmit={(data) => submitSection({
                    firstName: data.firstName,
                    lastName: data.lastName,
                    birthDate: data.birthDate,
                    companyName: data.companyName,
                    additionalInsured: data.additionalInsured,
                    additionalInsuredFirstName: data.additionalInsuredFirstName,
                    additionalInsuredLastName: data.additionalInsuredLastName,
                    additionalInsuredDateOfBirth: data.additionalInsuredDateOfBirth
                  }, 5)}
                  updating={updating}
                />
              } />
              <Route path="6" element={
                <PropertyAddress
                  firstName={lead.firstName}
                  propertyType={lead.propertyType}
                  addressLine1={lead.propertyAddressStreet}
                  addressLine2={lead.propertyAddressStreet2}
                  city={lead.propertyAddressCity}
                  state={lead.propertyAddressState}
                  zipCode={lead.propertyAddressZipCode}
                  onSubmit={(address: AddressDto) => submitSection({
                    propertyAddressStreet: address.addressLine1,
                    propertyAddressStreet2: address.addressLine2,
                    propertyAddressCity: address.city,
                    propertyAddressState: address.state,
                    propertyAddressZipCode: address.zipCode
                  }, 6)}
                  updating={updating}
                />
              } />
              <Route path="7" element={
                <PropertyUse
                  value={lead.questionBusinessFootTraffic
                    ? 'operating_a_business'
                    : lead.occupancy as string
                  }
                  onSubmit={(v) => {
                    switch (v) {
                      case 'operating_a_business':
                        submitSection({ questionBusinessFootTraffic: true }, 7);
                        break;
                      default:
                        submitSection({ occupancy: v as OccupancyHomeowner }, 7);
                        break;
                    }
                  }}
                  updating={updating}
                />
              } />
              <Route path="8" element={
                <RentalTimeline
                  value={lead.rentalTime}
                  onSubmit={(value) => submitSection({
                    rentalTime: value,
                    occupancy: value === RentalTime.SHORT_TERM ? OccupancyLandlord.SOME_VACANCY : OccupancyLandlord.FULLY_OCCUPIED
                  }, 8)}
                  updating={updating}
                />
              } />
              <Route path="9" element={
                <RentPerMonth
                  value={lead.rentalIncomePerMonth}
                  onSubmit={(value) => submitSection({ rentalIncomePerMonth: value }, 9)}
                  updating={updating}
                />
              } />
              <Route path="10" element={
                <PropertyConditions
                  propertyType={lead.propertyType}
                  value={lead.fixturesAndFinishes}
                  onSubmit={(v) => submitSection({ fixturesAndFinishes: v }, 10)}
                  updating={updating}
                />
              } />
              <Route path="11" element={
                <PropertyData
                  lead={lead}
                  onSubmit={(data) => submitSection({
                    propertyType: data.propertyType,
                    constructionType: data.constructionType,
                    yearBuilt: data.yearBuilt,
                    buildingSize: data.buildingSize,
                    numberOfStories: data.numberOfStories,
                    questionFuses: data.fuses,
                    questionPoolHotTubSpa: data.pool,
                    plumbingReplacementYear: data.plumbingReplacedYear,
                    roofReplacementYear: data.roofReplacedYear,
                    questionRoofReplaced: (data.yearBuilt && (data.roofReplacedYear > data.yearBuilt)),
                    questionPlumbingReplaced: (data.yearBuilt && (data.plumbingReplacedYear > data.yearBuilt))
                  }, 11)}
                  updating={updating}
                />
              } />
              <Route path="12" element={
                <Discounts
                  values={[
                    (lead.hasAutoInsurancePolicy ? 'auto_insurance_policy' : null),
                    (lead.hasFireDetectionSystem ? 'fire_detection_system' : null),
                    (lead.hasBurglarAlarmSystem ? 'burglar_alarm_system' : null),
                    (lead.hasFireExtinguisher ? 'fire_extinguisher' : null),
                    (lead.hasSmartWaterValve ? 'smart_water_valve' : null)
                  ].filter(x => !!x)}
                  onSubmit={(values) => {
                    const hasAutoInsurancePolicy = values.includes('auto_insurance_policy');
                    const hasFireDetectionSystem = values.includes('fire_detection_system');
                    const hasBurglarAlarmSystem = values.includes('burglar_alarm_system');
                    const hasFireExtinguisher = values.includes('fire_extinguisher');
                    const hasSmartWaterValve = values.includes('smart_water_valve');
                    submitSection({
                      hasAutoInsurancePolicy,
                      hasFireDetectionSystem,
                      hasBurglarAlarmSystem,
                      hasFireExtinguisher,
                      hasSmartWaterValve
                    }, 12);
                  }}
                  updating={updating}
                />
              } />
              <Route path="13" element={
                <FireDetectionFollowUp
                  value={lead.fireDetectionSystemAlertsEmergencyServices}
                  onContinue={(v) => submitSection({ fireDetectionSystemAlertsEmergencyServices: v }, 13)}
                  updating={updating}
                />
              } />
              <Route path="14" element={
                <RedFlags
                  hasUnrepairedDamage={lead.hasUnrepairedDamage}
                  hasKnownDefects={lead.hasKnownDefects}
                  hasOpenOrPendingClaims={lead.hasOpenOrPendingClaims}
                  isPartOfLawsuit={lead.isPartOfLawsuit}
                  isUnderForeclosure={lead.isUnderForeclosure}
                  onSubmit={(v) => submitSection({
                    hasUnrepairedDamage: v.hasUnrepairedDamage,
                    hasKnownDefects: v.hasKnownDefects,
                    hasOpenOrPendingClaims: v.hasOpenOrPendingClaims,
                    isPartOfLawsuit: v.isPartOfLawsuit,
                    isUnderForeclosure: v.isUnderForeclosure
                  }, 14)}
                  updating={updating}
                />
              } />
              <Route path="15" element={
                <PreviousClaims
                  previousClaims={lead.questionInsuranceClaims}
                  // previousClaimsExplanation={lead.previousClaimsExplanation}
                  onSubmit={(v) => submitSection({ questionInsuranceClaims: v }, 15)}
                  updating={updating}
                />
              } />
              <Route path="16" element={
                <LevelOfCoverage
                  value={lead.levelOfCoverage}
                  onSubmit={(v) => submitSection({ levelOfCoverage: v }, 16)}
                  updating={updating}
                />
              } />
              <Route path="17" element={
                <EffectiveDate
                  value={lead.effectiveDate}
                  onSubmit={(v) => submitSection({ effectiveDate: v }, 17)}
                  updating={updating}
                />
              } />
              <Route path="18" element={
                <Deductible
                  value={lead.deductible}
                  onSubmit={(v) => submitSection({ deductible: v }, 18)}
                  updating={updating}
                />
              } />
              <Route path="19" element={
                <MailingAddress
                  differentMailingAddress={lead.differentMailingAddress}
                  mailingAddress={{
                    addressLine1: lead.mailingAddressStreet,
                    addressLine2: lead.mailingAddressStreet2,
                    city: lead.mailingAddressCity,
                    state: lead.mailingAddressState,
                    zipCode: lead.mailingAddressZipCode
                  }}
                  updating={updating}
                  onSubmit={(differentMailingAddress, address) => {
                    if (differentMailingAddress) {
                      submitSection({
                        differentMailingAddress,
                        mailingAddressStreet: address.addressLine1,
                        mailingAddressStreet2: address.addressLine2,
                        mailingAddressCity: address.city,
                        mailingAddressState: address.state,
                        mailingAddressZipCode: address.zipCode
                      }, 19);
                    } else {
                      submitSection({
                        differentMailingAddress
                      }, 19);
                    }
                  }}
                />
              } />
              <Route path="20" element={
                <ContactInfo
                  firstName={lead.firstName}
                  email={lead.email}
                  phoneNumber={lead.phoneNumber}
                  onSubmit={(data) => submitSection({ email: data.email, phoneNumber: data.phoneNumber, status: LeadStatus.SUBMITTED }, 20)}
                  updating={updating}
                />
              } />
              <Route path="21" element={
                <ReferralSource
                  source={lead.source}
                  sourceMore={lead.sourceMore}
                  onSubmit={(source: string, sourceMore: string) => submitSection({ source, sourceMore }, 21)}
                  onSkip={() => {
                    questionIndexRef.current = 21;
                    navigate(`/onboarding/${getNextQuestion()}`);
                  }}
                  updating={updating}
                />
              } />
              <Route path="22" element={
                <End firstName={lead.firstName} onQuoteAnotherProperty={startOver} />
              } />
              <Route path="*" element={<Navigate to="1" replace/>}/>
            </Routes>
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              mt: { xs: 4, md: 8 },
            }}>
              <Box sx={{ maxWidth: { md: '60%' } }}>
                <Typography variant="body2" sx={{ fontSize: { xs: 10, md: 12 }, color: (t) => t.palette.grey['700'], textAlign: 'center',  }}>
                  Rentr Insurance Services dba Homeflow Insurance Services (“Homeflow”) is licensed as a property casualty insurance agency in all states in which products are offered.
                </Typography>
              </Box>
            </Box>
          </div>
        </Box>
      </Box>
    </Box>
  );
};