import CircleIcon from '@mui/icons-material/Circle';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import HomeIcon from '@mui/icons-material/Home';
import { Box, Button, Container, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import { ContactModal } from 'components/ContactModal';
import { ReactComponent as Logo } from 'images/logo.svg';
import { upperCase } from 'lodash';
import { useCurrentUser } from 'queries';
import { useState } from 'react';
import { WEBSITE_URL } from 'rentr-constants';
import { AuthService } from 'services';

interface Props {
  showHomeButton?: boolean;
  homeButtonPath?: string
}

export const Navigation = ({ showHomeButton, homeButtonPath }: Props) => {
  const [showContactModal, setShowContactModal] = useState(false);
  const { data: currentUser } = useCurrentUser();
  const [accountMenuAnchor, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleAccountClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAccountMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      height: 70,
      backgroundColor: '#fff',
      borderBottom: '1px solid rgba(0, 0, 0, 0.1)'
    }}>
      <Container maxWidth="xl" sx={{
        color: '#fff',
        alignItems: { xs: 'flex-start', md: 'center' },
        justifyContent: 'space-between',
        display: 'flex',
        gap: 2,
      }}>
        <a href={WEBSITE_URL}>
          <Logo style={{ display: 'block', height: 34, width: 'auto' }}/>
        </a>
        <Box display="flex" sx={{ alignItems: 'center', gap: 2 }}>
          {showHomeButton && (
            <Tooltip title="Dashboard" placement="bottom" arrow>
              <a href={homeButtonPath}>
                <HomeIcon sx={{ color: (t) => t.palette.grey['900'] }} />
              </a>
            </Tooltip>
          )}
          <Tooltip title="Need help?" placement="bottom" arrow>
            <IconButton onClick={() => setShowContactModal(true)}>
              <HelpOutlineIcon sx={{ color: (t) => t.palette.grey['800'] }} />
            </IconButton>
          </Tooltip>
          {currentUser && (
            <>
              <Button onClick={handleAccountClick} variant="text" color="inherit">
                <Box display="flex" sx={{ alignItems: 'center', gap: 1 }}>
                  <CircleIcon color="success" sx={{ fontSize: 12 }} />
                  <Typography variant="body1" sx={{ fontSize: 16, fontWeight: 500, color: (t) => t.palette.grey['800'] }}>
                    {upperCase(`${currentUser.firstName} ${currentUser.lastName}`)}
                  </Typography>
                </Box>
              </Button>
              <Menu
                onClose={handleAccountMenuClose}
                anchorEl={accountMenuAnchor}
                open={Boolean(accountMenuAnchor)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <MenuItem disabled>{`${currentUser.firstName} ${currentUser.lastName}`}</MenuItem>
                <MenuItem onClick={() => AuthService.logout(`${window.location.origin}/login`)}>Sign out</MenuItem>
              </Menu>
            </>
          )}
        </Box>
        <ContactModal
          open={showContactModal}
          onClose={() => setShowContactModal(false)}
        />
      </Container>
    </Box>
  );
};
